import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { TDefaultTheme, styled } from 'react-ui-kit-exante';

import HeaderStyles from './Header.module.css';
import { NAV_LINKS } from './constants';

const HeaderStyled = styled('header')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme?.palette?.success?.main,
    position: 'relative',
    padding: '0 24px',
    boxSizing: 'border-box',
  }),
);

const HeaderTitleStyled = styled('span')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    fontSize: '22px',
    color: theme?.color?.typo?.inverse,
  }),
);

const HeaderLinkStyled = styled(NavLink)(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'inline-block',
    textDecoration: 'none',
    color: theme?.color?.typo?.inverse,
    padding: '16px',
    '&:hover': {
      backgroundColor: theme?.color?.bg?.promo,
    },
    '&.active': {
      backgroundColor: theme?.color?.typo?.inverse,
      color: theme?.color?.typo?.action,
      opacity: 0.9,
    },
  }),
);

export const Header: FC = () => {
  return (
    <HeaderStyled>
      <HeaderTitleStyled>Branding</HeaderTitleStyled>
      <ul className={HeaderStyles.List}>
        {NAV_LINKS.map(({ to, label }) => (
          <li key={to}>
            <HeaderLinkStyled to={to}>{label}</HeaderLinkStyled>
          </li>
        ))}
      </ul>
    </HeaderStyled>
  );
};
