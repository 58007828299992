import { DateTimePickerSlotsComponentsProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import {
  endOfToday,
  endOfWeek,
  endOfYesterday,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subWeeks,
} from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';

import {
  TDateRangeFilterHandler,
  TPredefinedDateRange,
} from 'Components/Table/types';

import { useTranslator } from '../../../../contexts/TranslationContext';
import { getBrowserDateFormat } from '../../../../formatters';
import { getBrowserLocale } from '../../../../helpers';
import {
  BrandedDatePickerStyled,
  BrandedDateTimeStyled,
  DatePickerStyled,
  DateRangePickerStyled,
  DateTimeStyled,
  PredefinedDataRangeStyled,
} from '../Filters.styled';
import { TDateValue } from '../types';

import { PredefinedDateRanges } from './PredefinedDateRanges';

const componentsPropsWithClearable: Partial<DateTimePickerSlotsComponentsProps> =
  {
    actionBar: { actions: ['clear'] },
  };

interface IDateRangeInputProps {
  onChange: (values: TDateValue[]) => void;
  onToday?: (values: TDateValue[]) => void;
  onYesterday?: (values: TDateValue[]) => void;
  onLastWeek?: (values: TDateValue[]) => void;
  values: TDateValue[];
  withTime?: boolean;
  useLocale?: boolean;
  predefinedDateRanges?: {
    items: TPredefinedDateRange[];
    onChangeDateRange: (
      values: TDateValue[],
      onChange: TDateRangeFilterHandler,
    ) => void;
  };
  useBrandedPicker?: boolean;
}

export const DateRangeInput: FC<IDateRangeInputProps> = ({
  onChange,
  onToday,
  onYesterday,
  onLastWeek,
  values,
  withTime = false,
  useLocale = false,
  useBrandedPicker = false,
  predefinedDateRanges,
}) => {
  const { t, locale } = useTranslator();

  const [minDate, maxDate] = values ?? [null, null];
  const [startDate, setStartDate] = useState<TDateValue | Date>(minDate);
  const [endDate, setEndDate] = useState<TDateValue | Date>(maxDate);

  useEffect(() => {
    setStartDate(minDate ?? null);
    setEndDate(maxDate ?? null);
  }, [values]);

  const Picker = withTime ? DateTimeStyled : DatePickerStyled;
  const PickerBranded = withTime
    ? BrandedDateTimeStyled
    : BrandedDatePickerStyled;

  const notBrandedDateFormat = getBrowserDateFormat(
    withTime,
    useLocale,
    getBrowserLocale,
  );

  const setLocalComponentRange = useCallback((min, max) => {
    setStartDate(min);
    setEndDate(max);
  }, []);
  const setRange = useCallback(
    (min: Date, max: Date) => {
      setLocalComponentRange(min, max);
      onChange([min, max]);
    },
    [onChange, setLocalComponentRange, useLocale, withTime],
  );

  const setDateRangeToToday = useCallback(() => {
    const today = new Date();

    if (onToday) {
      const dayStart = startOfToday();
      const dayEnd = endOfToday();

      setLocalComponentRange(dayStart, dayEnd);
      onToday([dayStart, dayEnd]);
    } else {
      setRange(today, today);
    }
  }, [onToday, setLocalComponentRange, setRange, useLocale]);

  const setDateRangeToYesterday = useCallback(() => {
    const today = new Date();
    const yesterday = today;

    yesterday.setDate(today.getDate() - 1);

    if (onYesterday) {
      const yesterdayStart = startOfYesterday();
      const yesterdayEnd = endOfYesterday();

      setLocalComponentRange(yesterdayStart, yesterdayEnd);
      onYesterday([yesterdayStart, yesterdayEnd]);
    } else {
      setRange(yesterday, yesterday);
    }
  }, [onYesterday, setLocalComponentRange, setRange, useLocale]);

  const setDateRangeToLastWeek = useCallback(() => {
    const today = new Date();
    const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
      weekStartsOn: 1,
    });
    const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });

    if (onLastWeek) {
      setLocalComponentRange(startOfLastWeek, endOfLastWeek);
      onLastWeek([startOfLastWeek, endOfLastWeek]);
    } else {
      setRange(startOfLastWeek, endOfLastWeek);
    }
  }, [onLastWeek, setLocalComponentRange, setRange, useLocale]);

  const handleMinDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        onChange([null, maxDate]);
      } else if (!Number.isNaN(date.getTime())) {
        setStartDate(date);
        onChange([date, maxDate]);
      }
    },
    [maxDate, onChange, useLocale, withTime],
  );

  const handleMaxDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        onChange([minDate, null]);
      } else if (!Number.isNaN(date.getTime())) {
        setEndDate(date);
        onChange([minDate, date]);
      }
    },
    [minDate, onChange, useLocale, withTime],
  );

  const startDateObj =
    typeof startDate === 'string' ? new Date(startDate) : startDate;
  const endDateObj = typeof endDate === 'string' ? new Date(endDate) : endDate;

  const pickers = useBrandedPicker ? (
    <>
      <PickerBranded
        selectsStart
        onChange={handleMinDateChange}
        selected={startDateObj}
        startDate={startDateObj}
        endDate={endDateObj}
        minDate={null}
        maxDate={endDateObj}
        locale={locale}
        showTimeInput={withTime}
        showClearButton
        inputProps={{
          size: 'small',
          'data-test-id': 'filter__input--date-min',
        }}
      />
      <PickerBranded
        selectsEnd
        onChange={handleMaxDateChange}
        selected={endDateObj}
        startDate={startDateObj}
        endDate={endDateObj}
        minDate={startDateObj}
        locale={locale}
        showTimeInput={withTime}
        showClearButton
        inputProps={{
          size: 'small',
          'data-test-id': 'filter__input--date-max',
        }}
      />
    </>
  ) : (
    <>
      <Picker
        inputFormat={notBrandedDateFormat}
        value={startDate || null}
        onChange={handleMinDateChange}
        size="small"
        locale={locale}
        componentsProps={componentsPropsWithClearable}
        controlProps={{
          inputProps: {
            'data-test-id': 'filters__input--date-min',
          },
        }}
      />
      <Picker
        inputFormat={notBrandedDateFormat}
        value={endDate || null}
        onChange={handleMaxDateChange}
        size="small"
        locale={locale}
        componentsProps={componentsPropsWithClearable}
        controlProps={{
          inputProps: {
            'data-test-id': 'filters__input--date-max',
          },
        }}
      />
    </>
  );

  return (
    <DateRangePickerStyled>
      {pickers}
      <PredefinedDataRangeStyled
        onClick={setDateRangeToToday}
        data-test-id="filters__button--set-daterange-to-today"
      >
        {t('ui__table__filters__daterange__btn_today')}
      </PredefinedDataRangeStyled>
      <PredefinedDataRangeStyled
        onClick={setDateRangeToYesterday}
        data-test-id="filters__button--set-daterange-to-yesterday"
      >
        {t('ui__table__filters__daterange__btn_yesterday')}
      </PredefinedDataRangeStyled>
      <PredefinedDataRangeStyled
        onClick={setDateRangeToLastWeek}
        data-test-id="filters__button--set-daterange-to-last-week"
      >
        {t('ui__table__filters__daterange__btn_lastweek')}
      </PredefinedDataRangeStyled>
      {predefinedDateRanges && (
        <PredefinedDateRanges
          items={predefinedDateRanges.items}
          onChangeDateRange={predefinedDateRanges.onChangeDateRange}
          setLocalComponentRange={setLocalComponentRange}
          setRange={setRange}
        />
      )}
    </DateRangePickerStyled>
  );
};
