import * as Icons from '../../../Icons';
import { TDefaultTheme } from '../../../theme';

export const TRANSIENT_PROPS = [
  'iconSize',
  'iconName',
  'iconSize',
  'iconColor',
  'color',
];

export const defaultIconColors: Partial<
  Record<keyof typeof Icons, keyof TDefaultTheme['color']['icon']>
> = {
  AddIcon: 'action',
  DeclineIcon: 'action',
  SaveIcon: 'action',
  PlayIcon: 'action',
  CloseIcon: 'secondary',
  UploadIcon: 'action',
  DownloadIcon: 'secondary',
  SearchIcon: 'secondary',
  ExportIcon: 'secondary',
};
