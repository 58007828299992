import { isDate } from 'lodash';

import { TFetchData } from '../../../Inputs/AutocompleteAsync/types';
import { ISelectOption } from '../../../Inputs/Select';
import {
  FilterType,
  FilterValue,
  TDateValue,
  TNumberRangeValue,
} from '../types';

import { TFilterValueState } from './types';

export const defaultFilterValueByType: Record<FilterType, FilterValue> = {
  select: '',
  textInput: '',
  multiSelect: [],
  asyncSelect: '',
  asyncMultiSelect: [],
  numberRange: [null, null],
  dateInput: null,
  dateRange: [null, null],
  dateTimeRange: [null, null],
  checkbox: false,
};

const prepareNumberRangeValue = (value: unknown) => {
  if (typeof value === 'string') {
    return [value, null];
  }

  if (Array.isArray(value)) {
    return value.map((item) => {
      if (Number.isNaN(parseInt(item, 10))) {
        return null;
      }

      if (typeof item === 'string') {
        return Number(item);
      }

      return item;
    });
  }

  return defaultFilterValueByType.numberRange;
};

export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'select',
): string;
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'textInput',
): string;
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'multiSelect',
): string[];
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'asyncSelect',
): string[];
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'numberRange',
): TNumberRangeValue[];
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'dateInput',
): TDateValue;
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'dateRange',
): TDateValue[];
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: 'checkbox',
): boolean;
export function prepareValueByFilterType(
  value: TFilterValueState,
  type: FilterType,
): TFilterValueState {
  const isString = typeof value === 'string';
  const isArray = Array.isArray(value);

  switch (type) {
    case 'select':
    case 'textInput':
      return isString ? value : defaultFilterValueByType.select;
    case 'dateInput':
      return isString ? value : defaultFilterValueByType.dateInput;

    case 'multiSelect': {
      const isNullableValue = value === undefined || value === null;
      const isArrayNullable =
        isArray &&
        (value as []).some((item) => item === undefined || item === null);

      if (isString) {
        return [value as string];
      }

      return !isNullableValue && !isArrayNullable
        ? value
        : defaultFilterValueByType.multiSelect;
    }
    case 'asyncSelect': {
      const isNullableValue = value === undefined || value === null;

      if (isString) {
        return value;
      }

      const isArrayNullable =
        isArray &&
        (value as []).some((item) => item === undefined || item === null);

      if (!isArrayNullable && !isNullableValue) {
        return { value: value[0], label: value[1] };
      }

      return !isNullableValue ? value : defaultFilterValueByType.asyncSelect;
    }
    case 'numberRange':
      return prepareNumberRangeValue(value);

    case 'dateRange':
      if (isArray) {
        const isDateTimeFormat = value.length === 4;

        if (isDateTimeFormat) {
          const [minDate, minTime, maxDate, maxTime] = value;
          return [
            new Date(`${minDate} ${minTime}`),
            new Date(`${maxDate} ${maxTime}`),
          ];
        }
        return value.map((v) => (v ? new Date(v) : null));
      }
      return defaultFilterValueByType.dateRange;

    case 'checkbox': {
      const isTrue = value === 'true';
      const isFalse = value === 'false';

      if (isTrue) {
        return true;
      }

      if (isFalse) {
        return false;
      }

      return typeof value === 'boolean'
        ? value
        : defaultFilterValueByType.checkbox;
    }
    default:
      return value;
  }
}

export function preparedSelectOptions(options: string[] | ISelectOption[]) {
  return options.map((option) => {
    if (typeof option === 'string') {
      return { value: option, label: option };
    }

    return option;
  });
}

export const parseNumber = (value: string | null) =>
  value ? parseFloat(value) : null;

export const toDate = (val: string | number | Date): Date =>
  isDate(val) ? val : new Date(val);

export const isValidFetchDataOption = (fetchDataOption?: TFetchData): boolean =>
  fetchDataOption !== undefined && typeof fetchDataOption === 'function';
