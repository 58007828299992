import { DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentType, PropsWithChildren } from 'react';

import { blockNonNativeProps } from '../../helpers';

import { StyledDialogContentProps } from './Modal.types';

export const StyledDialogActions = styled(DialogActions)(() => ({
  padding: '0 24px 24px',
}));

export const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: blockNonNativeProps(['disableContentPaddings']),
})<StyledDialogContentProps>(({ theme, disableContentPaddings }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  minWidth: '350px',
  fontSize: '15px',
  color: theme?.color?.typo?.promo,
  fontFamily: theme?.font?.main,
  padding: disableContentPaddings ? '20px 0' : '20px 24px',
  a: {
    color: theme?.color?.typo?.action,
    transition: '0.3s',
    '&:hover': {
      color: theme?.color?.typo?.promo,
    },
  },
})) as ComponentType<PropsWithChildren<StyledDialogContentProps>>;
