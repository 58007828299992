import { styled } from '@mui/material/styles';
import { ComponentType, PropsWithChildren } from 'react';

import { blockNonNativeProps } from '../../../helpers';

import { RESIZER_WIDTH } from './const';
import { CellType } from './types';

export const RootStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const CellStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isFlexLayout']),
})<CellType>(({ isFlexLayout }) => ({
  height: 'auto',
  padding: '8px 24px',
  boxSizing: 'border-box',
  width: '100%',
  marginRight: isFlexLayout ? RESIZER_WIDTH : 0,
})) as ComponentType<PropsWithChildren<CellType>>;

export const RowStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  boxSizing: 'border-box',
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const SkeletonContainerStyled = styled('div')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

export const TableContainerStyled = styled('div')(() => ({
  overflowY: 'hidden',
  boxSizing: 'border-box',
}));

export const TableInfoStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '8px 24px',
  backgroundColor: theme.color.table.bg.basic2,
}));

export const TableBodyStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const PaginationContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '24px',
  backgroundColor: theme.color.table.bg.basic2,
}));

export const PaginationStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
