import InputIcon from '../../../assets/InputIcon.svg';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../consts';
import { formatDate, formatNumber } from '../../../formatters';
import { getBrowserLocale, toUTCDate } from '../../../helpers';
import { DefaultTableCell } from '../components';
import { FormattedTableCell } from '../components/FormattedTableCell';
import type { IColumn } from '../types';

export * from './dataForExpandedTable';
export * from './dataExpandedTableCopyPaste';

export const metaArguments = {
  columns: {
    control: 'array',
    description:
      'React-table column array. Required properties are Header and Accessor. For more information please use the reference https://react-table.tanstack.com/docs/api/useTable#column-options OR check interface IColumn',
  },
  hasFilters: { control: 'boolean', defaultValue: false },
  handleRowClick: {
    control: 'null',
    description: 'Callback to be executed on a row click',
  },
  disableSortBy: {
    control: 'boolean',
    description: 'Disable sorting',
    defaultValue: false,
  },
  hasPagination: { control: 'boolean', defaultValue: false },
  serverPaginationProps: {
    control: 'object',
    description:
      'Props for the customPaginationComponent. Contains following props: pageIndex: number; pageCount: number; pageSize: number; total: number; setPage: TSetPage; setPageSize: (pageSize: number) => void;',
  },
  customPaginationComponent: {
    control: 'object',
    description:
      'Component to be used for server pagination (create your own JSX)',
  },
  customFilterComponent: {
    control: 'object',
    description:
      'Component to be used for server filtering (create your own JSX)',
  },
  serverFilteringProps: {
    control: 'object',
    description:
      'Props for the customFilterComponent. If not provided, client side filtering will apply.',
  },
  handleCellDataEdit: {
    control: 'null',
    description:
      'Callback for cell editing. If not provided, cells wont be editable. index: number, columnId: string, value: string => void',
  },
  showConfirmationModal: {
    control: 'object',
    description:
      'Props for cell editing. If provided, a modal will be shown prompting user to confirm cell edit. [columnId: string]: boolean',
  },
  showTableInfo: {
    control: 'boolean',
    description: 'Shows table info and column selector',
    defaultValue: false,
  },
  showScrollbar: {
    control: 'boolean',
    description: 'Shows horizontal scrollbar',
    defaultValue: false,
  },
  singleColored: {
    control: 'boolean',
    description: 'Removes grey background from odd rows',
    defaultValue: false,
  },
  className: {
    control: 'string',
    description: 'Provides styles to component',
    defaultValue: '',
  },
  pageSize: {
    control: 'number',
    description: 'Numbers of rows per page',
    defaultValue: 20,
  },
  displayedColumnKeys: {
    control: 'array',
    description:
      'Array of collumns accessors - keys that should be shown on the table by default',
  },
  data: {
    control: 'array',
    description: 'Array of entities that should be rendered on the table',
  },
  noHeader: { control: 'boolean', defaultValue: false },
  pageSizes: { control: 'array', defaultValue: [5, 10, 20, 30] },
  manualSortBy: {
    control: 'boolean',
    defaultValue: false,
    description: 'Enables sorting detection functionality',
  },
  onSort: {
    control: 'null',
    description: 'Callback to be executed on a header cell click',
  },
  getHeaderProps: {
    description:
      'This core prop getter is required to to enable absolute layout for headers',
  },
  getColumnProps: {
    description:
      'This core prop getter is required to to enable absolute layout for columns',
  },
  getRowProps: {
    description:
      'This core prop getter is required to to enable absolute layout for rows',
  },
  getCellProps: {
    description:
      'This core prop getter is required to to enable absolute layout for rows cells',
  },
};

export type TData = {
  id: number;
  userType: string;
  lastLoginService: string | null;
  creationDate: string;
  username: string;
  primaryPhone: string | null;
  nameSurname: string | null;
  localization: string | null;
  legalEntity: string;
  lastLoginTime: string | null;
  company: string | null;
  hasAccessTo: string;
  isActive: boolean;
  age: number;
  accounts?: undefined;
  accountsMulti?: undefined;
};

export const dataForStorybook: TData[] = [
  {
    username: 'ros@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851788',
    nameSurname: 'Semyon Romanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-13 08:08:32.044',
    lastLoginService: 'symboldb',
    id: 1,
    creationDate: '2021-07-29',
    company: 'exante',
    isActive: true,
    age: 25,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'atp_desktop@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851799',
    nameSurname: 'atp autotestov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-03 15:38:13.000',
    lastLoginService: 'backoffice',
    id: -122054.55,
    creationDate: '2021-08-05',
    company: null,
    isActive: true,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'fl+2@exante.eu',
    userType: 'trader',
    primaryPhone: '79046425483',
    nameSurname: 'Lidiia F',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-19 09:17:32.694',
    lastLoginService: 'symboldb',
    id: -19270.65,
    creationDate: '2021-08-09',
    company: 'Exante',
    isActive: false,
    age: 18,
    hasAccessTo: 'crypto-treasury, symboldb, backoffice, crm',
  },
  {
    username: 'avo@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959331',
    nameSurname: 'Alex Ivanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-26 11:51:42.181',
    lastLoginService: 'symboldb',
    id: -30.95,
    creationDate: '2021-09-08',
    company: 'exante',
    isActive: false,
    age: 20,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'aig@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959388',
    nameSurname: 'Hewlett Packard',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-26 11:51:42.181',
    lastLoginService: 'webbo',
    id: -31,
    creationDate: '2022-09-08',
    company: 'exante',
    isActive: false,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'aig@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959388',
    nameSurname: 'Acer Aspire',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-27 11:51:42.181',
    lastLoginService: 'webbo',
    id: -172346272.11,
    creationDate: '2022-10-08',
    company: 'exante',
    isActive: false,
    age: 45,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'instsupport@exante.eu',
    userType: 'internal',
    primaryPhone: '+79091642179',
    nameSurname: 'Instrument Support',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-08-30 07:52:18.661',
    lastLoginService: 'backoffice',
    id: 254589,
    creationDate: '2021-08-30',
    company: 'exante',
    isActive: false,
    age: 30,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'pr',
    userType: 'trader',
    primaryPhone: null,
    nameSurname: null,
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-24 11:03:53.615',
    lastLoginService: 'backoffice',
    id: 762,
    creationDate: '',
    company: 'exante',
    isActive: true,
    age: 41,
    hasAccessTo:
      'crypto-treasury, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
];

export const lotsOfDataForStorybook: TData[] = [
  {
    username: 'ros@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851788',
    nameSurname: 'Semyon Romanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-13 08:08:32.044',
    lastLoginService: 'symboldb',
    id: -5,
    creationDate: '2021-07-29',
    company: 'exante',
    isActive: true,
    age: 25,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'atp_desktop@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851799',
    nameSurname: 'atp autotestov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-03 15:38:13.000',
    lastLoginService: 'backoffice',
    id: -10,
    creationDate: '2021-08-05',
    company: null,
    isActive: true,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'fl+2@exante.eu',
    userType: 'trader',
    primaryPhone: '79046425483',
    nameSurname: 'Lidiia F',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-19 09:17:32.694',
    lastLoginService: 'symboldb',
    id: 5,
    creationDate: '2021-08-09',
    company: 'Exante',
    isActive: false,
    age: 18,
    hasAccessTo: 'crypto-treasury, symboldb, backoffice, crm',
  },
  {
    username: 'avo@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959331',
    nameSurname: 'Alex Ivanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-26 11:51:42.181',
    lastLoginService: 'symboldb',
    id: -1,
    creationDate: '2021-09-08',
    company: 'exante',
    isActive: false,
    age: 20,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'instsupport@exante.eu',
    userType: 'internal',
    primaryPhone: '+79091642179',
    nameSurname: 'Instrument Support',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-08-30 07:52:18.661',
    lastLoginService: 'backoffice',
    id: 254589,
    creationDate: '2021-08-30',
    company: 'exante',
    isActive: false,
    age: 30,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'pr',
    userType: 'trader',
    primaryPhone: null,
    nameSurname: null,
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-24 11:03:53.615',
    lastLoginService: 'backoffice',
    id: 762,
    creationDate: '1970-01-01',
    company: 'exante',
    isActive: true,
    age: 41,
    hasAccessTo:
      'crypto-treasury, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'ros@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851788',
    nameSurname: 'Semyon Romanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-13 08:08:32.044',
    lastLoginService: 'symboldb',
    id: 245090,
    creationDate: '2021-07-29',
    company: 'exante',
    isActive: true,
    age: 25,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'atp_desktop@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851799',
    nameSurname: 'atp autotestov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-03 15:38:13.000',
    lastLoginService: 'backoffice',
    id: 248374,
    creationDate: '2021-08-05',
    company: null,
    isActive: true,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'fl+2@exante.eu',
    userType: 'trader',
    primaryPhone: '79046425483',
    nameSurname: 'Lidiia F',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-19 09:17:32.694',
    lastLoginService: 'symboldb',
    id: 249557,
    creationDate: '2021-08-09',
    company: 'Exante',
    isActive: false,
    age: 18,
    hasAccessTo: 'crypto-treasury, symboldb, backoffice, crm',
  },
  {
    username: 'avo@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959331',
    nameSurname: 'Alex Ivanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-26 11:51:42.181',
    lastLoginService: 'symboldb',
    id: 257423,
    creationDate: '2021-09-08',
    company: 'exante',
    isActive: false,
    age: 20,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'instsupport@exante.eu',
    userType: 'internal',
    primaryPhone: '+79091642179',
    nameSurname: 'Instrument Support',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-08-30 07:52:18.661',
    lastLoginService: 'backoffice',
    id: 254589,
    creationDate: '2021-08-30',
    company: 'exante',
    isActive: false,
    age: 30,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'pr',
    userType: 'trader',
    primaryPhone: null,
    nameSurname: null,
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-24 11:03:53.615',
    lastLoginService: 'backoffice',
    id: 762,
    creationDate: '1970-01-01',
    company: 'exante',
    isActive: true,
    age: 41,
    hasAccessTo:
      'crypto-treasury, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'ros@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851788',
    nameSurname: 'Semyon Romanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-13 08:08:32.044',
    lastLoginService: 'symboldb',
    id: 245090,
    creationDate: '2021-07-29',
    company: 'exante',
    isActive: true,
    age: 25,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'atp_desktop@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851799',
    nameSurname: 'atp autotestov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-03 15:38:13.000',
    lastLoginService: 'backoffice',
    id: 248374,
    creationDate: '2021-08-05',
    company: null,
    isActive: true,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'fl+2@exante.eu',
    userType: 'trader',
    primaryPhone: '79046425483',
    nameSurname: 'Lidiia F',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-19 09:17:32.694',
    lastLoginService: 'symboldb',
    id: 249557,
    creationDate: '2021-08-09',
    company: 'Exante',
    isActive: false,
    age: 18,
    hasAccessTo: 'crypto-treasury, symboldb, backoffice, crm',
  },
  {
    username: 'avo@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959331',
    nameSurname: 'Alex Ivanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-26 11:51:42.181',
    lastLoginService: 'symboldb',
    id: 257423,
    creationDate: '2021-09-08',
    company: 'exante',
    isActive: false,
    age: 20,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'instsupport@exante.eu',
    userType: 'internal',
    primaryPhone: '+79091642179',
    nameSurname: 'Instrument Support',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-08-30 07:52:18.661',
    lastLoginService: 'backoffice',
    id: 254589,
    creationDate: '2021-08-30',
    company: 'exante',
    isActive: false,
    age: 30,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'pr',
    userType: 'trader',
    primaryPhone: null,
    nameSurname: null,
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-24 11:03:53.615',
    lastLoginService: 'backoffice',
    id: 762,
    creationDate: '1970-01-01',
    company: 'exante',
    isActive: true,
    age: 41,
    hasAccessTo:
      'crypto-treasury, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'ros@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851788',
    nameSurname: 'Semyon Romanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-13 08:08:32.044',
    lastLoginService: 'symboldb',
    id: 245090,
    creationDate: '2021-07-29',
    company: 'exante',
    isActive: true,
    age: 25,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'atp_desktop@exante.eu',
    userType: 'trader',
    primaryPhone: '79527851799',
    nameSurname: 'atp autotestov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-12-03 15:38:13.000',
    lastLoginService: 'backoffice',
    id: 248374,
    creationDate: '2021-08-05',
    company: null,
    isActive: true,
    age: 35,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'fl+2@exante.eu',
    userType: 'trader',
    primaryPhone: '79046425483',
    nameSurname: 'Lidiia F',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-19 09:17:32.694',
    lastLoginService: 'symboldb',
    id: 249557,
    creationDate: '2021-08-09',
    company: 'Exante',
    isActive: false,
    age: 18,
    hasAccessTo: 'crypto-treasury, symboldb, backoffice, crm',
  },
  {
    username: 'avo@exante.eu',
    userType: 'internal',
    primaryPhone: '79263959331',
    nameSurname: 'Alex Ivanov',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-26 11:51:42.181',
    lastLoginService: 'symboldb',
    id: 257423,
    creationDate: '2021-09-08',
    company: 'exante',
    isActive: false,
    age: 20,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'instsupport@exante.eu',
    userType: 'internal',
    primaryPhone: '+79091642179',
    nameSurname: 'Instrument Support',
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-08-30 07:52:18.661',
    lastLoginService: 'backoffice',
    id: 254589,
    creationDate: '2021-08-30',
    company: 'exante',
    isActive: false,
    age: 30,
    hasAccessTo:
      'crypto-treasury, crypto-withdrawals, eggshell, clientsarea-api, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
  {
    username: 'pr',
    userType: 'trader',
    primaryPhone: null,
    nameSurname: null,
    localization: null,
    legalEntity: 'ALL',
    lastLoginTime: '2021-11-24 11:03:53.615',
    lastLoginService: 'backoffice',
    id: 762,
    creationDate: '1970-01-01',
    company: 'exante',
    isActive: true,
    age: 41,
    hasAccessTo:
      'crypto-treasury, auditlog, crm-api, symboldb_editor_backend, symboldb, backoffice, crm',
  },
];

export const columnsForStorybook: IColumn<TData>[] = [
  {
    Header: 'Text Input',
    accessor: 'id',
    type: 'textInput',
    sortType: 'basic',
    width: '120',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Multi Select',
    accessor: 'userType',
    type: 'multiSelect',
    width: '120',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Data Range',
    accessor: 'creationDate',
    type: 'dateTimeRange',
    width: '120',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Select',
    accessor: 'lastLoginService',
    type: 'select',
    width: '120',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'username',
    accessor: 'username',
    type: 'textInput',
    width: '300',
  },
  {
    Header: 'company',
    accessor: 'company',
    type: 'textInput',
    width: '300',
  },
];

export const columnsForStorybookWithAlignment: IColumn<TData>[] = [
  {
    Header: 'Text Input',
    accessor: 'id',
    type: 'textInput',
    sortType: 'basic',
    width: '120',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Multi Select',
    accessor: 'userType',
    type: 'multiSelect',
    width: '120',
    align: 'left',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Data Range',
    accessor: 'creationDate',
    type: 'dateTimeRange',
    width: '120',
    align: 'center',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'Select',
    accessor: 'lastLoginService',
    type: 'select',
    width: '120',
    align: 'center',
    cellStyle: {
      padding: 10,
    },
  },
  {
    Header: 'username',
    accessor: 'username',
    type: 'textInput',
    width: '300',
    align: 'right',
  },
  {
    Header: 'company',
    accessor: 'company',
    type: 'textInput',
    width: '300',
    align: 'right',
  },
];

export const columnsForStorybookWithResize = [
  {
    Header: 'Text Input',
    accessor: 'id',
    type: 'textInput',
  },
  {
    Header: 'Multi Select',
    accessor: 'userType',
    type: 'multiSelect',
  },
  {
    Header: 'Data Range',
    accessor: 'creationDate',
    type: 'dateRange',
  },
  {
    Header: 'Select',
    accessor: 'lastLoginService',
    type: 'select',
    filter: 'exact',
  },
];

export const columnsForStorybookWithIconsInHeader = [
  {
    Header: 'Text Input',
    HeaderWithIcon: <img alt="input" src={InputIcon} />,
    accessor: 'id',
    type: 'textInput',
    sortType: 'basic',
  },
  {
    Header: 'Multi Select',
    accessor: 'userType',
    type: 'multiSelect',
  },
  {
    Header: 'Data Range',
    accessor: 'creationDate',
    type: 'dateRange',
  },
  {
    Header: 'Select',
    accessor: 'lastLoginService',
    type: 'select',
    filter: 'exact',
  },
];

export const columnsForStorybookWithFooter: IColumn<TData>[] = [
  {
    Header: 'Id',
    accessor: 'id',
    sortType: 'basic',
    Footer: () => <b>Total</b>,
  },
  { Header: 'Name', accessor: 'nameSurname' },
  {
    Header: 'Age',
    accessor: 'age',
    Footer: ({ data }) => (
      <b style={{ fontSize: '18px' }}>
        {data.reduce((acc, curr) => curr.age + acc, 0)}
      </b>
    ),
  },
];

export const columnsForStorybookWithExportButton: IColumn<TData>[] = [
  { Header: 'Id', accessor: 'id', type: 'textInput', sortType: 'basic' },
  {
    Header: 'Name',
    accessor: 'nameSurname',
    type: 'textInput',
    sortType: 'basic',
  },
  { Header: 'Age', accessor: 'age', type: 'textInput', sortType: 'basic' },
];

export const columnsForStorybookWithFormatting: IColumn<TData>[] = [
  {
    Header: 'Formatted number',
    accessor: 'id',
    type: 'textInput',
    sortType: 'basic',
    formatting: 'number',
    width: '300',
  },
  {
    Header: 'Multi Select',
    accessor: 'userType',
    type: 'multiSelect',
    width: '300',
  },
  {
    Header: 'Date Range',
    accessor: 'creationDate',
    formatting: 'date',
    type: 'dateRange',
    width: '300',
  },
  {
    Header: 'Last Login Time',
    accessor: 'lastLoginTime',
    formatting: 'dateTime',
    type: 'dateRange',
    width: '300',
  },
  {
    Header: 'Select',
    accessor: 'lastLoginService',
    type: 'select',
    width: '300',
  },
  {
    Header: 'username',
    accessor: 'username',
    type: 'textInput',
    width: '300',
  },
];

export const columnsForStorybookWithCustomTooltip: IColumn<TData>[] = [
  {
    Header: 'Without tooltip',
    accessor: 'id',
    sortType: 'basic',
    width: '300',
    tooltip: 'off',
  },
  {
    Header: 'With default tooltip',
    accessor: 'userType',
    width: '300',
  },
  {
    Header: 'With custom tooltip',
    accessor: 'creationDate',
    width: '300',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value) => `My custom tooltip for ${value}`,
      },
    },
  },
  {
    Header: 'With top placement',
    accessor: 'username',
    width: '300',
    tooltip: {
      type: 'custom',
      options: {
        placement: 'top',
      },
    },
  },
];

export const translatorFallback = (key: string) =>
  ({
    ui__table__pagination__pagesize: 'Lines per page',
    ui__table__pagination__gotopage: 'Go to page',
    ui__table__filters__numrange__min_placeholder: 'min',
    ui__table__filters__numrange__max_placeholder: 'max',
    ui__table__filters__checkbox__label: 'Apply filter',
    ui__table__filters__textinput__placeholder: 'Enter value',
    ui__table__filters__multiselect__no_options: 'No options',
    ui__table__filters__daterange__btn_today: 'today',
    ui__table__filters__daterange__btn_yesterday: 'yesterday',
    ui__table__filters__daterange__btn_lastweek: 'last week',
    ui__table__filter__clear: 'Clear all filters',
    ui__table__filter__select_placeholder: 'Type to apply new filter',
    ui__table__filter__title: 'Filter list',
    ui__table__actions__add: 'New',
    ui__table__actions__close: 'Close',
    ui__table__actions__set_filters: 'Set filters',
    ui__table__actions__download_csv: 'Download CSV',
    ui__table__colselect__btn_reset: 'Reset to defaults',
    ui__table__colselect__btn_show_all: 'Show all',
    ui__table__colselect__btn_hide_all: 'Hide all',
    ui__table__colselect__search_placeholder: 'Search',
    ui__table__info__start_pfx: 'Showing',
    ui__table__info__end_pfx: 'to',
    ui__table__info__total_pfx: 'of',
    ui__table__info__nothing_to_show: 'Nothing to show',
    ui__table__cell__modal_confirm_edit__title: 'Confirmation Title',
    ui__table__cell__modal_confirm_edit__btn_cancel: 'Close',
    ui__table__cell__modal_confirm_edit__btn_confirm: 'Confirm',
    ui__table__cell__modal_confirm_edit__text:
      'Are you sure you want to edit the value?',
    ui__table__no_data: 'No data',
  }[key] || key);

export const serviceColumns = new Set(['actions']);
export const defaultActionColumnWidth = 80;
export const additionalActionWidth = 40;

export const MIN_TABLE_WIDTH = 30;
export const TABLE_WIDTH = 150;
export const MAX_TABLE_WIDTH = 400;
export const PAGINATION_HEIGHT = 80;

export const defaultColumn = {
  minWidth: MIN_TABLE_WIDTH,
  maxWidth: MAX_TABLE_WIDTH,
  Cell: (props: {
    value: unknown;
    column: IColumn<Record<string, unknown>>;
  }) => <DefaultTableCell {...props} FormattedTableCell={FormattedTableCell} />,
};

export const formatters = {
  number: (value: unknown, opt?: Intl.NumberFormatOptions) =>
    formatNumber(value, opt, getBrowserLocale),

  date: (value: string, dateFormat = DATE_FORMAT, useLocale = false) =>
    formatDate({ date: value, format: dateFormat, useLocale, toUTCDate }),

  dateTime: (
    value: string,
    dateFormat = DATE_WITH_TIME_FORMAT,
    useLocale = false,
  ) =>
    formatDate({
      date: value,
      withTime: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),

  dateUTC: (value: string, dateFormat = DATE_FORMAT, useLocale = false) =>
    formatDate({
      date: value,
      withTime: false,
      dateUTC: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),

  dateTimeUTC: (
    value: string,
    dateFormat = DATE_WITH_TIME_FORMAT,
    useLocale = false,
  ) =>
    formatDate({
      date: value,
      withTime: false,
      dateUTC: true,
      dateTimeUTC: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),
};

export const columnsForTableWithGroups = [
  {
    Header: 'Currency',
    accessor: 'currency',
    sortType: 'numeric',
  },
  {
    Header: 'Main group',
    columns: [
      {
        Header: 'First group',
        columns: [
          {
            Header: 'Value',
            accessor: 'value',
            formatting: 'number',
            sortType: 'numeric',
          },
          {
            Header: 'Timestamp',
            accessor: 'timestamp',
          },
        ],
      },
      {
        Header: 'Second group',
        columns: [
          {
            Header: 'Share percent',
            accessor: 'sharePercent',
          },
          {
            Header: 'Description',
            accessor: 'description',
            sortType: 'numeric',
          },
        ],
      },
    ],
  },
];
